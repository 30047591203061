import styled, { keyframes, css } from "styled-components";

import { device } from "../../../../../../utils/breakpoints";
import { fadeIn, fadeOut } from "../../../../../../utils/animations";
import { H2, H3, H4 } from "../../../../../../assets/styles/typography";

interface PageProps {
  animation?: string;
}

export const PageWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 88px 178px 40px 88px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms linear
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;

  @media ${device.computer} {
    padding: 88px 120px 80px 120px;
  }

  @media ${device.desktop} {
    padding: 128px 120px 120px 120px;
  } ;
`;

export const Title = styled.h1`
  margin: 0;
  font-size: 32px;
  font-weight: 34px;
  color: #000000;
  white-space: pre-line;

  @media ${device.computer} {
    ${H3}
  }

  @media ${device.desktop} {
    ${H2}
  } ;
`;

export const HorizontalDivider = styled.hr`
  margin: 24px 0;
  width: 112px;
  height: 6px;
  background: #013cff;

  @media ${device.tablet}, ${device.tabletLandscape} {
    margin: 32px 0 24px 0;
  }

  @media ${device.computer} {
    width: 96px;
    height: 8px;
    margin: 40px 0 0 0;
  } ;
`;

export const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media ${device.tablet}, ${device.tabletLandscape} {
    flex-direction: column;
  }

  @media ${device.computer} {
    margin-top: 100px;
  }

  @media ${device.desktop} {
    margin-top: 135px;
  }
`;

export const OfferWrapper = styled.div`
  width: calc(33% - 32px);
  margin: 0 32px 16px 0;
  height: auto;
  color: #000000;

  @media ${device.tablet}, ${device.tabletLandscape} {
    width: 100%;
    margin: 24px 0;
  }

  @media ${device.computer} {
    margin: 0 32px 87px 0;
  }

  @media ${device.desktop} {
    width: calc(33% - 60px);
    margin: 0 60px 87px 0;
  }

  &:hover {
    cursor: pointer;
    color: #013cff;
  }
`;

export const OfferTitle = styled.h2`
  margin: 0;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 700;
  color: inherit;
  white-space: pre-line;

  @media ${device.computer} {
    ${H4}
  } ;
`;

export const OfferSubtitle = styled.h3`
  margin: 0;
  font-size: 1.2rem;
  font-weight: 400;
  color: inherit;
  white-space: pre-line;

  @media ${device.laptop} {
    font-size: 2rem;
  }

  @media ${device.laptopL} {
    font-size: 2.4rem;
  } ;
`;

export const moveLeftRightLoop = (width: number) => keyframes`
  0% {
    right: 0;
  };

  50% {
    right: calc(100% - ${width}px);
  };

  100% {
    right: 0;
  };
`;

export const moveRightLeftLoop = (width: number) => keyframes`
  0% {
    right: calc(100% - ${width}px);
  };

  50% {
    right: 0;
  };

  100% {
    right: calc(100% - ${width}px);
  };
`;

export interface InfoProps {
  width: number;
  pageWidth: number;
  index: number;
}

export const Info = styled.div`
  position: relative;
  min-height: 90px;
  margin: 8px 0;

  @media ${device.tablet}, ${device.tabletLandscape} {
    margin: 80px 0 40px 0;
  }

  @media ${device.tabletLandscape} {
    margin: 40px 0;
  }

  @media ${device.laptopL} {
    min-height: 114px;
  } ;
`;

export const InfoText = styled.div`
  position: absolute;
  top: ${(props: InfoProps) => `${props.index * 30}px`};
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  color: #013cff;
  white-space: pre;
  animation: ${(props: InfoProps) =>
      props.width < props.pageWidth
        ? moveRightLeftLoop(props.width)
        : moveLeftRightLoop(props.width)}
    20s linear infinite;
  overflow: hidden;

  &:nth-of-type(2) {
    animation: ${(props: InfoProps) =>
        props.width < props.pageWidth
          ? moveLeftRightLoop(props.width)
          : moveRightLeftLoop(props.width)}
      20s linear infinite;
  }

  @media ${device.laptopL} {
    font-size: 26px;
    line-height: 38px;
    top: ${(props: InfoProps) => `${props.index * 38}px`};
  } ;
`;

export const Description = styled.span`
  width: calc(30% - 32px);
  margin: 0;
  margin-right: calc(3% + 32px);
  display: block;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  white-space: pre-line;
  word-break: keep-all;

  @media ${device.tablet} {
    width: 100%;
    margin: 12px 0;
  }

  @media ${device.tabletLandscape} {
    width: 100%;
    margin: 8px 0;
  }

  @media ${device.desktop} {
    width: calc(28% - 60px);
    margin-right: calc(5% + 60px);
  } ;
`;

export const NoOfferDescription = styled(Description)`
  width: 100% !important;
  margin-top: 18px;
`;

export const Link = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: #013cff;
  font-weight: 700;
  white-space: pre-line;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
